import { differenceInDays, subDays } from "date-fns";
import _ from "lodash";
import { getItemMinBalance, getShippingMethodFromStateTransitionMetadata } from "../../../../api/standards";
import { ItemVendorConfig, ItemWithConfig, SourceMetadata, StateTransitionFull, Vendor } from "../../../../api/types";
import { generateId } from "../../../../utils";
import { SourceTypePrefix, SupplyPlanningTableRow } from "../types";
import { getRowSourceName, parseStringToDate } from "../utils";

export const sortStateTransitions = (stateTransitions: StateTransitionFull[]) => _.sortBy(stateTransitions, "at");
export const sortRows = (rows: any[]) => _.sortBy(rows, "txnDate");

const getLeadTime = (vendor: ItemVendorConfig, item: ItemWithConfig) => {
  return vendor.vendor_time_days + vendor.shipment_time_days + (item.config.finishing_time_days || 0);
};

export const sortAllRows = (rows: SupplyPlanningTableRow[]) => {
  const sortedRows = rows.sort((a, b) => {
    if (a.sourceRowName === "Balance" && b.sourceRowName === "Balance") {
      return a.isMainRevision === b.isMainRevision ? 0 : a.isMainRevision ? -1 : 1;
    }

    if (a.sourceRowName !== "Balance" && b.sourceRowName !== "Balance") {
      const aTxnDate = new Date(a.txnDate).getTime();
      const bTxnDate = new Date(b.txnDate).getTime();
      return aTxnDate - bTxnDate;
    }

    return a.sourceRowName === "Balance" ? -1 : 1;
  });
  return sortedRows;
};

function valueOrZeroIfNaN(n: any) {
  const isNumeric = !isNaN(parseFloat(n)) && isFinite(n);
  if (isNumeric) {
    return n;
  }
  return 0;
}

export const initialRowDataPreparation = (rows: SupplyPlanningTableRow[]) => {
  rows.forEach((node, nodeIndex) => {
    // If either of these values is non-number, make it 0
    // Prevents cascading undefined/NaNs
    node.quantity = valueOrZeroIfNaN(node.quantity);
    node.endingBalance = valueOrZeroIfNaN(node.endingBalance);

    // All rows adopt first row's minBal
    node.minBalance = rows[0].minBalance;

    if (node.sourceRowName === "Balance") {
      // Balance rows
      if (nodeIndex !== 0) {
        // For non-1st / "main item" balance rows, carry forward balance as qty, and rollforward balance
        node.quantity = node.endingBalance;
        node.endingBalance = node.quantity + rows[nodeIndex - 1].endingBalance;
      }
    } else {
      // Non-balance rows
      node.endingBalance = node.quantity + rows[nodeIndex - 1].endingBalance;
    }

    if (!node.isMainRevision) {
      node.dateRequired = "";
    }
  });
  return rows;
};

export const getVendorConfigByCondition = (item: ItemWithConfig) => {
  const [vendor] = _.orderBy(
    _.entries(item?.config?.vendor),
    [
      ([, vendor]) => vendor.item_vendor_priority,
      ([, vendor]) => vendor.shipment_time_days + vendor.vendor_time_days
    ],
    [
      "asc", // Ascending order for condition priority
      "desc" // Descending order for total lead time
    ],
  );

  const [vendorId, vendorConfig] = vendor;

  return { id: +vendorId, config: vendorConfig };
};

const getRequisitionQuantity = (item: ItemWithConfig) => {
  const { config: vendorConfig } = getVendorConfigByCondition(item);
  const lotSize = item?.config?.item_lot_size || 0;
  const minOrder = vendorConfig?.minimum_order_quantity || 0;

  return _.ceil(Math.max(minOrder / lotSize, 1)) * lotSize;
};

export const getHelperColumns = (item: ItemWithConfig, location: { at: string, type: string }, vendors: Vendor[] | undefined) => {
  const { id: vendorId, config: vendorConfig } = getVendorConfigByCondition(item);
  
  const vendor = _.find(vendors, { id: +vendorId });

  const now = new Date();
  const txnDate = new Date(location.at);

  const leadTime = getLeadTime(vendorConfig, item);
  let dateRequired = subDays(txnDate, leadTime);
  if (location.type !== "outbound") {
    dateRequired = subDays(txnDate, leadTime - (item.config.finishing_time_days || 0));
  }
  const requisitionQuantity = getRequisitionQuantity(item);

  if (differenceInDays(now, txnDate) >= leadTime) return;
  return {
    vendor: vendor?.name,
    method: "Ocean",
    dateRequired,
    requisitionQuantity,
    // TODO: $ per MT to be done later
    incomePerMonth: "",

    // Config
    shipmentTimeDays: vendorConfig.shipment_time_days,
    vendorTimeDays: vendorConfig.vendor_time_days,
  };
};

export const getLocationDates = (location: any) => {
  switch (location?.type) {
    case "inbound":
      return {
        locationOriginally: parseStringToDate(location?.originally_at),
        locationExpected: parseStringToDate(location?.at),
      };
    case "outbound":
      return {
        departLocationOriginally: parseStringToDate(location?.originally_at),
        departLocationExpected: parseStringToDate(location?.at),
      };
  }
};

export const getTxnDate = (item: ItemWithConfig, location: any) => {
  if (location.type === "outbound") {
    const finishingTimeDays = item.config?.finishing_time_days || 0;
    return subDays(location.at, finishingTimeDays);
  }
  return parseStringToDate(location.at);
};

export const getBalanceSnapshotValues = (item: ItemWithConfig, stateId: number) => {
  const itemConfig = item?.config;
  const locationConfig = itemConfig?.location?.[stateId];

  const startingBalance = locationConfig?.balance_snapshot_quantity;
  const balanceSnapshotDate = new Date(locationConfig?.balance_snapshot_date * 1000);

  return { startingBalance, balanceSnapshotDate };
};

export const calculateEndingBalances = (rows: SupplyPlanningTableRow[], initialEndingBalance: number = 0) => {
  const rowsWithOrder = rows.map((row, index) => ({ ...row, order: index }));
  const sortedRows = sortRows(_.cloneDeep(rowsWithOrder));
  const endingBalancesWithTxnDates: { endingBalance: number; order: number }[] = sortedRows.reduce(
    (acc, row) => {
      const previousEndingBalance = acc.at(-1)?.endingBalance || 0;
      const currentQuantity = row?.quantity || 0;
      const newEndingBalance = previousEndingBalance + currentQuantity;

      acc.push({ endingBalance: newEndingBalance, order: row.order || 0 });
      return acc;
    },
    [{ endingBalance: initialEndingBalance, order: 0 }],
  );
  const endingBalances = _.sortBy(endingBalancesWithTxnDates, "order").map((row) => row.endingBalance);

  return endingBalances;
};

export const parseQuantity = (location: any) => {
  if (!location.quantity) return undefined;
  switch (location.type) {
    case "inbound":
      return location.quantity;
    case "outbound":
      return -location.quantity;
  }
};

const mapStateTransitionsWithTypes = (stateId: number, stateTransitions: StateTransitionFull[]): any[] => {
  const inbounds = _.filter(stateTransitions, { to_state_id: stateId }).map((transition) => ({
    ...transition,
    type: "inbound",
  }));
  const outbounds = _.filter(stateTransitions, { from_state_id: stateId }).map((transition) => ({
    ...transition,
    type: "outbound",
  }));

  return sortStateTransitions([...inbounds, ...outbounds]);
};

export const getRowDataByStateId = (
  item: ItemWithConfig,
  stateId: number,
  stateTransitions: StateTransitionFull[],
  vendors: Vendor[] | undefined,
  stateIdsSelected: (number | string)[],
) => {
  const minBalance = getItemMinBalance(item, stateIdsSelected);
  const mappedStateTransitions = mapStateTransitionsWithTypes(stateId, stateTransitions);

  return mappedStateTransitions?.map((locationTransition) => {
    const shipmentTransition = _.find(stateTransitions, {
      id: locationTransition?.prior_transition_id,
    }) as StateTransitionFull;
    const vendorTransition = _.find(stateTransitions, {
      id: shipmentTransition?.prior_transition_id,
    }) as StateTransitionFull;

    return {
      id: locationTransition.id,
      vendorId: vendorTransition?.id,
      shipmentId: shipmentTransition?.id,
      shipmentStateId: shipmentTransition?.state_id,
      type: locationTransition.type,

      workflowId: locationTransition?.workflow_execution?.workflow_id || null,
      workflowRunId: locationTransition?.workflow_execution?.workflow_run_id || null,
      approvalStatus: locationTransition?.workflow_execution?.search_attributes?.approval_status,

      ...getHelperColumns(item, locationTransition, vendors),

      vendorOriginally: parseStringToDate(vendorTransition?.originally_at),
      vendorExpected: parseStringToDate(vendorTransition?.at),
      shipmentOriginally: parseStringToDate(shipmentTransition?.originally_at),
      shipmentExpected: parseStringToDate(shipmentTransition?.at),

      ...getLocationDates(locationTransition),

      txnDate: getTxnDate(item, locationTransition),
      quantity: parseQuantity(locationTransition),
      minBalance,

      vendorName: vendorTransition?.name,
      shipmentContainerNumber: shipmentTransition?.source_metadata?.container_no,
      shippingMethod: getShippingMethodFromStateTransitionMetadata(shipmentTransition).toString(),
      locationName: locationTransition?.name,

      sourceRowName: getRowSourceName(locationTransition),
      sourceLastSync: locationTransition?.source_last_sync,
      actual: {
        vendor: vendorTransition?.actual,
        shipment: shipmentTransition?.actual,
        location: locationTransition?.actual,
      },

      source_metadata: locationTransition?.source_metadata,

      sourceType: locationTransition?.source_metadata?.source_type,
      sourceRowIdHash: locationTransition?.source_row_id_hash || null,
      createdRowIdHash: locationTransition?.created_row_id_hash || null,

      editable: false,
      new: false,
    };
  });
};

export const getInitialRowDataByStateIds = (item: ItemWithConfig, stateIds: (number | string)[]) => {
  const minBalance = getItemMinBalance(item, stateIds);

  const balanceSnapshotValues = stateIds.map((stateId) => getBalanceSnapshotValues(item, +stateId));
  const startingBalance = balanceSnapshotValues.reduce((acc, cur) => acc + cur.startingBalance, 0);
  const balanceSnapshotDate = _.maxBy(balanceSnapshotValues, "balanceSnapshotDate")?.balanceSnapshotDate;

  const initialRow = {
    txnDate: balanceSnapshotDate,
    minBalance,
    sourceRowName: "Balance",
    id: generateId(),
  };

  return { initialRow, startingBalance };
};

export const getRowData = (
  item: ItemWithConfig,
  stateIds: (number | string)[],
  stateTransitions: StateTransitionFull[],
  vendors: Vendor[] | undefined,
  itemId: number,
) => {
  const rowsByStateIds = stateIds
    ?.map((stateId) => getRowDataByStateId(item, +stateId!, stateTransitions, vendors, stateIds))
    .flat();

  const initialRowDataByStateIds = getInitialRowDataByStateIds(item, stateIds);
  const sortedRows = sortRows(rowsByStateIds);
  const endingBalances = calculateEndingBalances(sortedRows, initialRowDataByStateIds.startingBalance);
  const rows = [initialRowDataByStateIds.initialRow, ...sortedRows];

  const mappedRows: SupplyPlanningTableRow[] = rows.map((row, rowIndex) => ({
    ...row,
    endingBalance: endingBalances?.[rowIndex],
    variant: item.variant,
    isMainRevision: item.id.toString() === itemId.toString(),
  }));
  return mappedRows;
};

const NS_ACCOUNT_DEFAULT = "5457165";

export const buildSourceLink = (source_metadata: SourceMetadata | Partial<SourceMetadata> | null | undefined) => {
  if (
    !source_metadata ||
    !source_metadata.source_id ||
    (!source_metadata.source_type && !source_metadata.source_name)
  ) {
    return null;
  }

  const { source_id, source_type, source_ns_account, source_name } = source_metadata;

  // First check explicit source type if exists
  let type_slug: string | null = null;
  if (source_type) {
    switch (true) {
      case source_type.startsWith(SourceTypePrefix.PO):
        type_slug = "accounting/transactions/purchord";
        break;
      case source_type.startsWith(SourceTypePrefix.REQ):
        type_slug = "accounting/transactions/purchreq";
        break;
      case source_type.startsWith(SourceTypePrefix.SO):
        type_slug = "accounting/transactions/salesord";
        break;
      case source_type.startsWith(SourceTypePrefix.DP):
        type_slug = "accounting/inventory/demandplanning/itemdemandplan";
        break;
    }
  }
  // If unsuccessful and source name (eg PO123) exists, try that -- NOTE: likely can deprecate
  if (!type_slug && source_name) {
    switch (true) {
      case source_name.startsWith(SourceTypePrefix.PO):
        type_slug = "accounting/transactions/purchord";
        break;
      case source_name.startsWith(SourceTypePrefix.REQ):
        type_slug = "accounting/transactions/purchreq";
        break;
      case source_name.startsWith(SourceTypePrefix.SO):
        type_slug = "accounting/transactions/salesord";
        break;
    }
  }
  if (!type_slug) {
    return null;
  }

  // Now get the NS account (falling back to primary/prod env, with idea that any created rows will have source_ns_account set)
  // Need to convert from 1010101_SB1 format to url format 1010101-sb1 (often will be a no-op)
  // [dplan] https://5457165.app.netsuite.com/app/accounting/inventory/demandplanning/itemdemandplan.nl?id=279264
  let ns_account = source_ns_account ?? NS_ACCOUNT_DEFAULT;
  ns_account = ns_account.replace("_", "-").toLowerCase();
  return `https://${ns_account}.app.netsuite.com/app/${type_slug}.nl?id=${source_id}`;
};
