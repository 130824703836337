import { SourceMetadata } from "../../../api/types";

interface Actual {
  vendor: boolean;
  shipment: boolean;
  location: boolean;
}

export interface SupplyPlanningTableRow {
  id: string | number;
  txnDate: string | Date;
  minBalance: number;
  sourceRowName: string;
  endingBalance: number;
  variant: string;
  isMainRevision: boolean;
  quantity: number;
  vendorId?: number;
  shipmentId?: number;
  shipmentStateId?: number;
  type?: string;
  workflowId?: number | null;
  workflowRunId?: number | null;
  vendorOriginally?: string | Date;
  vendorExpected?: string | Date;
  shipmentOriginally?: string | Date;
  shipmentExpected?: string | Date;
  locationOriginally?: string | Date;
  locationExpected?: string | Date;
  vendorName?: string;
  shipmentTimeDays?: number;
  vendorTimeDays?: number;
  shippingMethod?: string;
  requisitionQuantity?: number;
  locationName?: string;
  sourceLastSync?: string | null;
  actual?: Actual;
  source_metadata?: SourceMetadata;
  order?: number;
  sourceType?: string;
  sourceRowIdHash?: string;
  createdRowIdHash?: string | null;
  editable?: boolean;
  dateRequired?: string | Date;
  vendor?: string;
  method?: string;
  new?: boolean;
}

export interface IRequisition {
  vendor: string;
  method: string;
  dateRequired: Date;
  requisitionQuantity: number;
  incomePerMonth: number;
  vendorOriginally: Date;
  vendorExpected: Date;
  shipmentOriginally: Date;
  shipmentExpected: Date;
  locationOriginally: Date;
  locationExpected: Date;
  txnDate: Date;
  quantity: number;
  endingBalance: number;
  minBalance: number;
  departLocationOriginally: Date;
  departLocationExpected: Date;
  vendorName: string;
  shipmentContainerNumber: string;
  shippingMethod: string;
  locationName: string;
  sourceRowName: string;
  sourceLastSync: string;

  // Config
  editable?: boolean;
  new: boolean;

  vendorId: number;
  shipmentId: number;
  shipmentStateId: number;

  workflowId: string | null;
  workflowRunId: string | null;
  source_metadata: SourceMetadata | null;
}

export enum SourceTypePrefix {
  PO = "PO",
  REQ = "REQ",
  SO = "SO",
  DP = "DP",
}
